<template>
  <AuthenticationPageLayout>
    <v-container style="min-height: calc(100vh - 170px)" fill-height>
      <v-row align="center" justify="center">
        <v-col cols="12" class="white--text caption mt-6">
          <img width="100%" height="auto" src="/img/graphics/bot_surfing.svg">
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-col class="pa-12 text-center">
          <h1 class="white--text">
            {{ $t("oops") }}
          </h1>
          <span class="white--text">
            {{ $t("page_looking_for_doesnt_exist_or_error_occurred") }}
          </span>
          <br>
          <v-btn color="secondary" to="/" class="mt-2">
            {{ $t("back_to_home") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </AuthenticationPageLayout>
</template>

<script>
import AuthenticationPageLayout from "@/components/layout/AuthenticationPageLayout.vue";
import translations from "@/components/locales";

export default {
  name: "NotFound",
  i18n: translations("NotFound"),
  components: { AuthenticationPageLayout },
  mounted() {
    this.$store.dispatch("fetchUser");
  },
};
</script>

<style scoped>
img {
  max-width: 350px;
}
</style>

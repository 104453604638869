import { render, staticRenderFns } from "./NotFoundPage.vue?vue&type=template&id=77fa39ca&scoped=true"
import script from "./NotFoundPage.vue?vue&type=script&lang=js"
export * from "./NotFoundPage.vue?vue&type=script&lang=js"
import style0 from "./NotFoundPage.vue?vue&type=style&index=0&id=77fa39ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_css-loader@6.7.3_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.93.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77fa39ca",
  null
  
)

export default component.exports
export default [
  /** ====================================================================================================
     * SETTINGS
     =====================================================================================================*/
  {
    path: "/settings",
    component: () => import(/* webpackChunkName: "settings" */ "@/modules/Settings/SettingsPage"),
    meta: {
      feature: "Settings",
      tracking: {
        sections: ["settings"]
      },
      breadcrumbs: [
        { title: "Settings", routeName: "settings" },
      ]
    },
    name: "settings",
    props: true
  }
];

import Vue from "vue";
import Vuex from "vuex";
import {
  AccountType,
  AllowedFeature,
  AssessmentType,
  ReportAssessmentsType,
} from "@YenzaCT/sdk";
import auth from "../modules/Authentication/store/auth.module";
import progress from "./modules/progress.module";
import timer from "@/store/modules/timer.module";
import cv from "../modules/CV/store/cv.module";
import onboarding from "../modules/Onboarding/store/onboarding.module";
import api from "@/library/api.lib";
import router from "@/router/router";
import { fetchConfig, setCachedConfig } from "@/library/appConfiguration";
import { RESEARCH_ASSESSMENTS } from "@/library/constants";
import _ from "lodash";

Vue.use(Vuex);

const getDefaultState = () => ({
  user: null,
  initialised: false,
  loading: false,
  menuCollapsed: false,
  snackBarMessage: "",
  appLoaded: false,
  fatalError: false,
  appConfiguration: null,
});

function getScore(metric) {
  return { score: metric?.score || 0 };
}

function formatUserMetrics(assessmentKey, metrics) {
  if (assessmentKey === "big_5") {
    return {
      open_mindedness: getScore(metrics.open_mindedness, false),
      conscientiousness: getScore(metrics.conscientiousness, false),
      extraversion: getScore(metrics.extraversion, false),
      agreeableness: getScore(metrics.agreeableness, false),
      emotional_stability: getScore(metrics.emotional_stability, true),
    };
  }

  // This is to format the legacy RPS results where the totals where not
  // averaged
  if (
    assessmentKey === "rock_paper_scissors" &&
    metrics.total &&
    metrics.total.number_of_levels
  ) {
    return {
      level_1: metrics.level_1,
      level_2: metrics.level_2,
      level_3: metrics.level_3,
      total: {
        total_score: metrics.total.total_score / metrics.total.number_of_levels,
        accuracy: metrics.total.accuracy / metrics.total.number_of_levels,
        accuracy_adjusted:
          metrics.total.accuracy_adjusted / metrics.total.number_of_levels,
        efficiency: metrics.total.efficiency / metrics.total.number_of_levels,
        number_attempted: metrics.total.number_attempted,
        number_correct: metrics.total.number_correct,
        number_incorrect: metrics.total.number_incorrect,
        number_of_levels: metrics.total.number_of_levels,
        penalty: metrics.total.penalty / metrics.total.number_of_levels,
        speed: metrics.total.speed,
      },
    };
  }

  // This is to format the legacy RPS results where the totals where not
  // averaged
  if (
    assessmentKey === "maze" &&
    metrics.total &&
    metrics.total.number_of_levels
  ) {
    return {
      level_1: metrics.level_1,
      level_2: metrics.level_2,
      level_3: metrics.level_3,
      total: {
        total_score: metrics.total.total_score / metrics.total.number_of_levels,
        accuracy: metrics.total.accuracy / metrics.total.number_of_levels,
        accuracy_adjusted:
          metrics.total.accuracy_adjusted / metrics.total.number_of_levels,
        efficiency: metrics.total.efficiency / metrics.total.number_of_levels,
        velocity: metrics.total.velocity / metrics.total.number_of_levels,
        number_attempted: metrics.total.number_attempted,
        number_correct: metrics.total.number_correct,
        number_incorrect: metrics.total.number_incorrect,
        number_of_levels: metrics.total.number_of_levels,
        penalty: metrics.total.penalty / metrics.total.number_of_levels,
        speed: metrics.total.speed,
      },
    };
  }

  return metrics;
}

const store = new Vuex.Store({
  state: getDefaultState,
  actions: {
    setAppConfiguration({ commit }, config) {
      commit("SET_APP_CONFIGURATION", config);
    },
    async updateAppConfiguration({ commit }) {
      try {
        const config = await fetchConfig();
        setCachedConfig(config);
        commit("SET_APP_CONFIGURATION", config);
        commit("SET_APP_LOADED", true);
      } catch (e) {
        commit("SET_FATAL_ERROR", e.message);
      }
    },
    /**
     * Fetches a user from the DB using a session
     *
     * @param dispatch
     * @param commit
     * @param token
     * @returns {*}
     */
    async fetchUser({ dispatch }) {
      try {
        const res = await api().get("auth/get-user");

        if (res.status === 204) {
          await store.dispatch("setInitialised", true);
        } else if (res.status === 200) {
          await store.dispatch("setInitialised", true);
          await dispatch("setupUser", { user: res.data });
        }

        return res;
      } catch (e) {
        return e;
      }
    },
    async handleYapiError({ dispatch }, e) {
      if (e.response) {
        if (e.response.status === 401) {
          await dispatch("logoutUser");
          return await router.push({
            name: "home",
            query: { redirect: window.location.pathname },
          });
        }
      }
    },
    setAppLoaded({ commit }, hasLoaded) {
      commit("SET_APP_LOADED", hasLoaded);
    },
    setInitialised({ commit }, data) {
      commit("SET_INITIALISED", data);
    },
    collapseMenu({ commit }) {
      commit("TRIGGER_MENU_COLLAPSE_OR_EXPAND");
    },
    /**
     * Reset the global user state back to default
     * @param dispatch
     */
    setSnackBarMessage({ commit }, message) {
      commit("SET_SNACK_BAR_MESSAGE", message);
    },
    /**
     * Reset the global user state back to default
     * @param dispatch
     */
    resetGlobalState({ commit, dispatch }) {
      // Reset all Modules
      dispatch("resetCvState");
      // Reset the user object
      commit("RESET_USER_STATE");
    },
    /**
     * Takes in a user object and uses it to initialise the entire app state
     * @param dispatch
     * @param user
     */
    async setUserGlobalState({ dispatch, commit }, user) {
      commit("SET_USER_STATE", user);

      // Calls the progress module and sets the users app progress
      await dispatch("setUserProgress", { user });
    },
    async setUserConsents({ commit }, consents) {
      // Update the consents in the root user store
      commit("UPDATE_USER_CONSENTS", consents);
    },
    async setUserMetrics(
      { commit, state, dispatch },
      { metrics, updatedAt, id }
    ) {
      // Update the specific metric in the user state object
      commit("UPDATE_USER_METRICS", {
        metrics,
        updatedAt,
        id,
      });

      // Update the users progress given the altered user state
      await dispatch("setUserProgress", {
        user: state.user,
        module: "assessments",
      });
    },
    async commitProfileSectionChange(
      { commit, dispatch, state },
      { key, data }
    ) {
      // Update the section in the users profile
      commit("UPDATE_USER_PROFILE", {
        key,
        data,
      });

      // Update the users progress given the altered user state
      await dispatch("setUserProgress", { user: state.user });
    },
    /**
     * Update the users cv data
     *
     * @param commit
     * @param key Specifies the part of the cv to update
     * @param data The data payload to be sent to the API
     * @returns {Promise<AxiosResponse<T>>}
     */
    async updateUserProfile(
      { commit, dispatch, state },
      { apiRoute, profileData, apiMethod = "post", isSubDocument, section }
    ) {
      let response;

      commit("SET_LOADING", true);

      try {
        // Update the users cv
        if (apiMethod === "put") {
          response = await api().put(`users/${apiRoute}`, profileData);
        } else if (apiMethod === "patch") {
          response = await api().patch(`users/${apiRoute}`, profileData);
        } else {
          response = await api().post(`users/${apiRoute}`, profileData);
        }

        // Loop through the response data and update the cv in the state
        if (isSubDocument) {
          commit("ADD_USER_PROFILE_SUBDOCUMENT", {
            section,
            data: response.data[Object.keys(response.data)[0]],
          });
        } else {
          for (const [key, data] of Object.entries(response.data)) {
            commit("UPDATE_USER_PROFILE", {
              key,
              data,
            });
          }
        }

        // Update the users progress given the altered user state
        await dispatch("setUserProgress", { user: state.user });
      } catch (e) {
        commit("SET_LOADING", false);
        throw e;
      }

      commit("SET_LOADING", false);

      return response;
    },
    async addFavouriteToUser({ commit, dispatch, state }, { type, id }) {
      let response;

      try {
        response = await api().put(`users/favourites/${type}/${id}`);

        commit("SET_USER_FAVOURITES", {
          type,
          data: response.data,
        });

        // Update the users progress given the altered user state
        await dispatch("setUserProgress", { user: state.user });
      } catch (e) {
        commit("SET_LOADING", false);
        throw e;
      }

      commit("SET_LOADING", false);
    },
    async deleteFavouriteFromUser({ commit, dispatch, state }, { type, id }) {
      let response;

      try {
        response = await api().delete(`users/favourites/${type}/${id}`);

        commit("SET_USER_FAVOURITES", {
          type,
          data: response.data,
        });

        // Update the users progress given the altered user state
        await dispatch("setUserProgress", { user: state.user });
      } catch (e) {
        commit("SET_LOADING", false);
        throw e;
      }

      commit("SET_LOADING", false);
    },
    async setIfSectionHidden({ commit }, { section, hide }) {
      let response;

      try {
        // Sign the user up
        response = await api().post("users/cv/hide-section", {
          section,
          hide,
        });
        commit("UPDATE_USER_PROFILE", {
          key: "hiddenSections",
          data: response.data.hiddenSections,
        });
      } catch (e) {
        // Handle errors from the signup process

        throw new Error(e);
      }

      return response;
    },
    /**
     * Deletes an item in a section in the user profile data
     *
     * @param commit
     * @param key Specifies the part of the cv to update
     * @param data The data payload to be sent to the API
     * @returns {Promise<AxiosResponse<T>>}
     */
    async deleteUserProfileSectionItem(
      { commit, state, dispatch },
      { apiRoute, id, section }
    ) {
      let response;

      commit("SET_LOADING", true);

      try {
        // Update the users cv
        response = await api().delete(`users/${apiRoute}/${id}`);

        // Filter out the section item that must be removed
        const newSectionItems = state.user.profile[section].filter(
          (item) => item !== id
        );

        // Update the section with the filtered data
        commit("UPDATE_USER_PROFILE", {
          key: section,
          data: newSectionItems,
        });

        // Update the users progress given the altered user state
        await dispatch("setUserProgress", { user: state.user });
      } catch (e) {
        commit("SET_LOADING", false);
        throw new Error(e);
      }

      commit("SET_LOADING", false);

      return response;
    },
    /**
     * Update a sub-document of the user cv (eg Experiences, Achievements)
     *
     * @param commit
     * @param key Specifies the part of the cv to update
     * @param data The data payload to be sent to the API
     * @returns {Promise<AxiosResponse<T>>}
     */
    async updateUserProfileSubDocument(
      { commit, state, dispatch },
      { apiRoute, profileData, apiMethod = "post", section }
    ) {
      let response;

      commit("SET_LOADING", true);

      try {
        // Update the users cv
        if (apiMethod === "put") {
          response = await api().put(`users/${apiRoute}`, profileData);
        } else if (apiMethod === "patch") {
          response = await api().patch(`users/${apiRoute}`, profileData);
        } else {
          response = await api().post(`users/${apiRoute}`, profileData);
        }

        // Find which record to update

        const responseKey = Object.keys(response.data)[0];

        // Loop through the response data and update the cv in the state
        for (
          let index = 0;
          index < state.user.profile[section].length;
          index++
        ) {
          if (
            state.user.profile[section][index]._id ===
            response.data[responseKey]._id
          ) {
            commit("UPDATE_USER_PROFILE_SUBDOCUMENT", {
              key: section,
              data: response.data[responseKey],
              index,
            });
            break;
          }
        }

        // Update the users progress given the altered user state
        await dispatch("setUserProgress", { user: state.user });
      } catch (e) {
        commit("SET_LOADING", false);
        throw new Error(e);
      }

      commit("SET_LOADING", false);

      return response;
    },
    /**
     * Delete a sub-document of the user cv (eg Experiences, Achievements)
     *
     * @param commit
     * @param key Specifies the part of the cv to update
     * @param data The data payload to be sent to the API
     * @returns {Promise<AxiosResponse<T>>}
     */
    async deleteUserProfileSubDocument(
      { commit, state, dispatch },
      { apiRoute, id, section }
    ) {
      let response;

      commit("SET_LOADING", true);

      try {
        // Update the users cv
        response = await api().delete(`users/${apiRoute}/${id}`);

        // Find the document in the current state using the index, and remove it
        for (
          let index = 0;
          index < state.user.profile[section].length;
          index++
        ) {
          if (state.user.profile[section][index]._id === id) {
            commit("REMOVE_USER_PROFILE_SUBDOCUMENT", {
              key: section,
              index,
            });
            break;
          }
        }

        // Update the users progress given the altered user state
        await dispatch("setUserProgress", { user: state.user });
      } catch (e) {
        commit("SET_LOADING", false);
        throw new Error(e);
      }

      commit("SET_LOADING", false);

      return response;
    },
    async createInteraction({ commit }, interaction) {
      let response;

      try {
        // Fetch the assessment
        response = await api().post("/users/interactions", {
          ...interaction,
        });
      } catch (e) {
        throw new Error(e);
      }

      commit("ADD_USER_INTERACTION", interaction);

      return response;
    },
    setLoading({ commit }, status) {
      commit("SET_LOADING", status);
    },
    confirmUserEmail({ commit }) {
      commit("CONFIRM_USER_EMAIL_ADDRESS");
    },
    confirmUserCellphone({ commit }) {
      commit("CONFIRM_USER_CELL_PHONE");
    },
    /**
     * Uses a slug to fetch the corresponding help content from Sanity.
     * @param dispatch
     * @param slug
     * @returns {Promise<AxiosResponse & helpContentData>}
     */
    async getHelpContent({ dispatch }, slug) {
      let response;
      dispatch("setLoading", true, { root: true });

      try {
        response = await api().get(`help/${slug}`);
      } catch (e) {
        dispatch("setLoading", false, { root: true });
        throw new Error(e);
      }
      dispatch("setLoading", false, { root: true });

      return response.data;
    },
  },
  getters: {
    // APP SETUP
    getAppConfiguration: (state) => state.appConfiguration,
    isStateUserValid: (state) => !!state.user,
    isLoading: (state) => state.loading,
    getLastAssessmentTime: (state) =>
      new Date(state.user.assessments.updatedAt),

    // Only allow access to features available on the instance
    canAccessFeature: (state) => (feature) => {
      const allowedFeatures = _.get(
        AllowedFeature,
        [state.user.country, state.user.app.accountType],
        []
      );
      const availableFeatures = _.keys(
        _.pickBy(
          state.appConfiguration.features,
          (value, feature) => value && _.includes(allowedFeatures, feature)
        )
      );
      return _.includes(availableFeatures, feature);
    },

    canUpgradeToFeature: (state, getters) => (feature) => {
      // if they can already access, they cannot upgrade
      if (getters.canAccessFeature(feature)) return false;

      const powerAccountFeatures = _.get(
        AllowedFeature,
        [state.user.country, AccountType.Power],
        []
      );

      // Filter out features not available on this instance
      const availablePowerFeatures = _.keys(
        _.pickBy(
          state.appConfiguration.features,
          (value, feature) => value && _.includes(powerAccountFeatures, feature)
        )
      );

      return _.includes(availablePowerFeatures, feature);
    },

    // Show features the user can access OR can upgrade to
    canSeeFeature: (state, getters) => (feature) =>
      getters.canAccessFeature(feature) || getters.canUpgradeToFeature(feature),

    isResearchUser: (state) =>
      state.user.app.researchAssessments &&
      state.user.app.researchAssessments.length > 0,
    areAllResearchAssessmentsComplete: (state) => {
      if (
        state.user.app.researchAssessments &&
        state.user.app.researchAssessments.length > 0
      )
        for (const assessment of state.user.app.researchAssessments) {
          const researchAssessments = RESEARCH_ASSESSMENTS[assessment];

          if (!state.user.assessments[assessment]) return false;

          for (let i = 0; i < researchAssessments.length; i++) {
            if (!state.user.assessments[researchAssessments[i]]) return false;
          }
        }

      return true;
    },

    // ASSESSMENTS
    isAssessmentComplete: (state) => (assessmentKey) =>
      !!state.user.assessments[assessmentKey],
    getAssessmentMetric: (state) => (assessmentKey) =>
      state.user.assessments[assessmentKey] ?? null,
    areAllReportAssessmentsComplete: (state) => (reportKey) =>
      Object.values(ReportAssessmentsType[reportKey]).every(
        (assessment) => state.user.assessments[assessment] ?? null
      ),

    // ONBOARDING
    getUser: (state) => state.user,
    appStage: (state, getters) => {
      if (
        !getters.isInitialProfileComplete ||
        !getters.hasConsented ||
        state.user.app.accountType === "free"
      )
        return "onboarding";

      if (getters.isResearchUser && !getters.areAllResearchAssessmentsComplete)
        return "research";

      if (!getters.isAssessmentComplete(AssessmentType.HollandCode))
        return "workerType";

      return "fullAccess";
    },
    isInitialProfileComplete: (state) => {
      const { initialProfile } = state.appConfiguration;
      for (const key in initialProfile) {
        if (initialProfile[key] && key === "rsaIdNumber" && !state.user.profile.idNumber) {
          return false;
        } else if (initialProfile[key] && key !== "rsaIdNumber" && !state.user.profile[key]) {
          return false;
        }
      }
      return true;
    },
    hasConsented: (state) => state.user.consents.terms.granted,

    // PROFILE
    getAuthenticationMethod: (state) =>
      state.user && state.user.auth
        ? state.user.auth.currentSessionAuthMethod
        : null,
    getEmailAddress: (state) => state.user.email,
    getCellPhone: (state) => state.user.phone,
    getAccountType: (state) => state.user ? state.user.app.accountType : null,
    getUserNickname: (state) =>
      !state.user.profile.nickname
        ? state.user.profile.firstName
          ? state.user.profile.firstName
          : ""
        : state.user.nickname,
    getUserEmail: (state) => state.user.profile.email,
    getUserTenantSlug: (state) =>
      state.user.app.cohort &&
      state.user.app.cohort.institution &&
      state.user.app.cohort.institution.tenant
        ? state.user.app.cohort.institution.tenant.slug
        : null,
    getUserEmailSHA256: (state) => state.user.emailSHA256,
    getSessionAuthenticationMethod: (state) =>
      state.user.auth.currentSessionAuthMethod,
    getUserMetrics: (state) => (assessmentKey) =>
      formatUserMetrics(assessmentKey, state.user.assessments[assessmentKey]),

    getUserFirstName: (state) =>
      state.user && state.user.profile ? state.user.profile.firstName : null,
    getProfileData: (state) => (profileKey) => state.user.profile[profileKey],
    getUserFavouriteIds: (state) => (type) =>
      state.user.profile.favourites[type],
    getAccountCreationTime: (state) => state.user.createdAt,
    getUserFullname: (state) => {
      if (state.user.profile.firstName) {
        return (
          state.user.profile.firstName +
          (!state.user.profile.lastName
            ? ""
            : ` ${state.user.profile.lastName}`)
        );
      }

      return null;
    },
    getConsentStatus: (state) => (consent) =>
      state.user.consents[consent] ? state.user.consents[consent] : null,
  },
  mutations: {
    SET_FATAL_ERROR(state, status) {
      state.fatalError = status;
    },
    SET_APP_CONFIGURATION(state, config) {
      state.appConfiguration = config;
    },
    SET_APP_LOADED(state, status) {
      state.appLoaded = status;
    },
    SET_INITIALISED(state, initialised) {
      state.initialised = initialised;
    },
    TRIGGER_MENU_COLLAPSE_OR_EXPAND(state) {
      state.menuCollapsed = !state.menuCollapsed;
    },
    RESET_USER_STATE(state) {
      state.user = null;
    },
    SET_USER_STATE(state, user) {
      state.user = user;
    },
    SET_LOADING(state, status) {
      state.loading = status;
    },
    UPDATE_USER_PROFILE(state, { key, data }) {
      state.user.profile[key] = data;
    },
    SET_USER_FAVOURITES(state, { type, data }) {
      state.user.profile.favourites[type] = data;
    },
    ADD_USER_PROFILE_SUBDOCUMENT(state, { section, data }) {
      state.user.profile[section].push(data);
    },
    UPDATE_USER_PROFILE_SUBDOCUMENT(state, { key, data, index }) {
      // Using vue.set to ensure reactivity
      Vue.set(state.user.profile[key], index, data);
    },
    REMOVE_USER_PROFILE_SUBDOCUMENT(state, { key, index }) {
      state.user.profile[key].splice(index, 1);
    },
    CONFIRM_USER_EMAIL_ADDRESS(state) {
      state.user.emailVerified = true;
      state.user.auth.currentSessionAuthVerified = true;
    },
    CONFIRM_USER_CELL_PHONE(state) {
      state.user.phoneVerified = true;
      state.user.auth.currentSessionAuthVerified = true;
    },
    ADD_USER_INTERACTION(state, interaction) {
      state.user.interactions.push(interaction);
    },
    UPDATE_USER_CONSENTS(state, consents) {
      state.user.consents = { ...consents };
    },
    UPDATE_USER_METRICS(state, { metrics, updatedAt, id }) {
      state.user.assessments.updatedAt = updatedAt;
      state.user.assessments[id] = metrics;
    },
    SET_SNACK_BAR_MESSAGE(state, message) {
      state.snackBarMessage = message;
    },
    UPDATE_USER_CONSENT(state, consent) {
      state.user.app.termsAgreement = consent.termsAgreement;
      state.user.app.guardianEmail = consent.guardianEmail;
      state.user.app.legalAge = consent.legalAge;
    },
  },
  modules: {
    auth,
    progress,
    timer,
    cv,
    onboarding,
  },
});

export default store;

import { AssessmentType } from "@YenzaCT/sdk";
import store from "@/store";

export default [
  /** ====================================================================================================
     * RESEARCH DASHBOARD
     =====================================================================================================*/
  {
    path: "/research",
    name: "researchDashboard",
    meta: {
      breadcrumbs: [{ title: "Research" }],
      appStage: "research",
    },
    component: () =>
      import(
        /* webpackChunkName: "research" */ "@/modules/AssessmentResearch/pages/ResearchDashboard"
      ),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isResearchUser) return next({ name: "profileHomePage" });

      return next();
    },
  },
  {
    path: "/research/assessments/personality-traits/instruction",
    name: "big5ResearchAssessmentInstructions",
    meta: {
      appStage: "research",
    },
    component: () =>
      import(
        /* webpackChunkName: "research" */ "@/modules/Reports/pages/Big5/QuizPracticePage/Big5PracticePage.vue"
      ),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isResearchUser) return next({ name: "profileHomePage" });
      if (store.getters.isAssessmentComplete(AssessmentType.Big5))
        return next({ name: "researchDashboard" });
      return next();
    },
  },
  {
    path: "/research/assessments/personality-traits/quiz",
    name: "big5ResearchAssessmentQuiz",
    meta: {
      appStage: "research",
    },
    component: () =>
      import(
        /* webpackChunkName: "research" */ "@/modules/Reports/pages/Big5/QuizPage/Big5QuizPage.vue"
      ),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isResearchUser) return next({ name: "profileHomePage" });
      if (store.getters.isAssessmentComplete(AssessmentType.Big5))
        return next({ name: "researchDashboard" });
      return next();
    },
  },
  {
    path: "/research/assessments/worker-type/instructions",
    name: "workerTypeResearchAssessmentInstructions",
    meta: {
      appStage: "research",
    },
    component: () =>
      import(
        /* webpackChunkName: "research" */ "@/modules/Reports/pages/HollandCode/QuizPracticePage/QuizPracticePage.vue"
      ),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isResearchUser) return next({ name: "profileHomePage" });
      if (store.getters.isAssessmentComplete(AssessmentType.HollandCode))
        return next({ name: "researchDashboard" });
      return next();
    },
  },
  {
    path: "/research/assessments/worker-type/quiz",
    name: "workerTypeResearchAssessmentQuiz",
    meta: {
      appStage: "research",
    },
    component: () =>
      import(
        /* webpackChunkName: "research" */ "@/modules/Reports/pages/HollandCode/QuizPage/HollandCodeQuizPage.vue"
      ),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isResearchUser) return next({ name: "profileHomePage" });
      if (store.getters.isAssessmentComplete(AssessmentType.HollandCode))
        return next({ name: "researchDashboard" });
      return next();
    },
  },
  {
    path: "/research/assessments/interests",
    name: "interestsResearchAssessment",
    meta: {
      appStage: "research",
    },
    component: () =>
      import(
        /* webpackChunkName: "research" */ "@/modules/AssessmentResearch/pages/InterestsQuiz.vue"
      ),
    beforeEnter: async (to, from, next) =>
      // if (!store.getters.isResearchUser) return next({ name: "profileHomePage" });
      // if (store.getters.isAssessmentComplete(AssessmentType.Interests))
      //   return next({ name: "researchDashboard" });
      next()
    ,
  },
  {
    path: "/research/assessments/entrepreneurial_intent",
    name: "entrepreneurialIntentResearchAssessment",
    meta: {
      appStage: "research",
    },
    component: () =>
      import(
        /* webpackChunkName: "research" */ "@/modules/AssessmentResearch/pages/EntrepreneurialIntentQuiz.vue"
      ),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isResearchUser) return next({ name: "profileHomePage" });
      if (
        store.getters.isAssessmentComplete(AssessmentType.EntrepreneurialIntent)
      )
        return next({ name: "researchDashboard" });
      return next();
    },
  },
  {
    path: "/research/assessments/ipip",
    name: "ipipResearchAssessment",
    meta: {
      appStage: "research",
    },
    component: () =>
      import(
        /* webpackChunkName: "research" */ "@/modules/AssessmentResearch/pages/IPIPQuiz.vue"
      ),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isResearchUser) return next({ name: "profileHomePage" });
      if (store.getters.isAssessmentComplete(AssessmentType.Ipip))
        return next({ name: "researchDashboard" });
      return next();
    },
  },
  {
    path: "/research/assessments/managing_emotion",
    name: "managingEmotionResearchAssessment",
    meta: {
      appStage: "research",
    },
    component: () =>
      import(
        /* webpackChunkName: "research" */ "@/modules/Reports/pages/EmotionalIntelligence/ManagingEmotionsQuiz/ManagingEmotionsQuiz.vue"
      ),
    beforeEnter: async (to, from, next) => {
      if (!store.getters.isResearchUser) return next({ name: "profileHomePage" });
      if (store.getters.isAssessmentComplete(AssessmentType.Ipip))
        return next({ name: "researchDashboard" });
      return next();
    },
  },
];

import api from "@/library/api.lib";

const signupObject = {
  signupStarted: null,
  voucherCode: null,
  emailAddress: null,
  cellPhone: null,
  emailVerificationSent: false,
  cellPhoneVerificationSent: false,
  consents: {
    marketing: {
      granted: null
    },
    terms: {
      granted: null
    }
  }
};

const getDefaultState = () => ({
  type: null,
  data: signupObject
});

export default {
  namespaced: true,
  state: getDefaultState,
  actions: {

    /**
     * Sets the voucher code.
     * @param commit
     * @param voucherCode
     */
    setVoucherCode({ commit }, voucherCode) {
      commit("SET_VOUCHER_CODE", voucherCode);
    },

    /**
     * Validates the provided voucher code
     */
    async validateAccessCode({ dispatch }, { accessCode }) {
      let response;

      dispatch("setLoading", true, { root: true });

      // Call the API and log the user in
      try {
        response = await api().post("auth/accessCode/validate", { accessCode });
      } catch (e) {
        dispatch("setLoading", false, { root: true });

        return e.response;
      }

      dispatch("setLoading", false, { root: true });

      return response;
    },
    /**
     * Redeem an voucher code for a logged in user
     * @param dispatch
     * @param state
     * @param code
     * @returns {Promise<AxiosResponse<any>|*>}
     */
    async redeemAccessCode({ dispatch }, { code }) {
      let response;

      dispatch("setLoading", true, { root: true });

      // Call the API and log the user in
      try {
        response = await api().get("redeem", { params: { code } });
      } catch (e) {
        dispatch("setLoading", false, { root: true });
        return e.response;
      }

      // Get the new user login
      await dispatch("fetchUser", {}, { root: true });

      dispatch("setLoading", false, { root: true });

      return response;
    },

  },
  getters: {

  },
  mutations: {
    SET_VOUCHER_CODE(state, voucherCode) {
      state.data.voucherCode = voucherCode;
    },
  }
};

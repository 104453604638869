<template>
  <div v-cloak id="app">
    <!-- To get sticky app bar to work -->
    <meta
      name="viewport"
      content="height=device-height,
                      width=device-width, initial-scale=1.0,
                      minimum-scale=1.0, maximum-scale=1.0,
                      user-scalable=no"
    >

    <v-app v-if="!isAppLoaded || fatalError || !appConfiguration">
      <FatalErrorCard v-if="fatalError" :fatal-error="fatalError" />
    </v-app>
    <v-app v-else>
      <router-view />
    </v-app>
  </div>
</template>

<script>
import store from "./store";
import i18n from "./i18n";
import router from "./router/router";
import { loadDynamicScripts } from "@/library/dynamicScripts";
import { setSignupCookies } from "@/helpers/RouteAccessChecksHelper";
import FatalErrorCard from "@/components/common/FatalErrorCard.vue";

export default {
  name: "App",
  components: { FatalErrorCard },
  store,
  i18n,
  router,
  computed: {
    /**
     * Triggers the loading spinner prior to app loading completing
     * @returns {any}
     */
    isAppLoaded() {
      return store.state.appLoaded;
    },
    fatalError() {
      return store.state.fatalError;
    },
  },

  async created() {
    // Loads any dynamic scripts that need to be included in the app
    // Example: OneTrust Cookie Banner
    loadDynamicScripts(this.appConfiguration.integrations);

    // Set up signup cookies
    this.setSignupCookies();
  },
  async mounted() {
    // Get the app configuration
    await store.dispatch("updateAppConfiguration");

    // Used to track re-engagement
    this.$analyticsEvents.openedApp();

    // Set language
    this.setLanguage();

    // Set fav icon
    this.setFavicon();

    // Set custom fonts
    this.setFonts();

    // Set custom fonts
    this.setCssVariables();
  },
  metaInfo: {
    title: process.env.VUE_APP_TITLE,
  },
  methods: {
    setCssVariables() {
      if (!store.state.appConfiguration.cssVariables) return;

      for (const [key, variable] of Object.entries(
        store.state.appConfiguration.cssVariables
      )) {
        document.documentElement.style.setProperty(`--${key}`, variable);
      }
    },
    /**
     * Sets the custom fonts for the app
     */
    setFonts() {
      if (store.state.appConfiguration.fonts) {
        const { fonts } = store.state.appConfiguration;

        const style = document.createElement("style");
        style.type = "text/css";

        style.innerHTML = Object.keys(fonts)
          .map((fontWeight) => `
            @font-face {
                font-family: 'CustomFont-${fontWeight}';
                font-weight: ${fontWeight};
                src: url('${fonts[fontWeight]}');
            }
        `)
          .join("\n");
        document.head.appendChild(style);
      }
    },
    /**
     * Sets the Fav Icon to the one provided via app config, if it is available
     */
    setFavicon() {
      if (store.state.appConfiguration.images.favicon) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = store.state.appConfiguration.images.favicon;
      }
    },
    setLanguage() {
      const storedLanguage = localStorage.getItem("language");

      if (storedLanguage) this.$root.$i18n.locale = storedLanguage;
      else if (this.appConfiguration.locales.default) {
        this.$root.$i18n.locale = this.appConfiguration.locales.default;
        localStorage.setItem("language", this.appConfiguration.locales.default);
      } else {
        this.$root.$i18n.locale = "en";
        localStorage.setItem("language", "en");
      }
    },
    setSignupCookies() {
      setSignupCookies();
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/assessments";

@layer base {
  :root {

    /* Spacing */
    --spacing-none: 0px;
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 12px;
    --spacing-lg: 16px;
    --spacing-xl: 24px;
    --spacing-2xl: 32px;
    /* Opacity */
    --opacity-weak: 0.3;
    --opacity-medium: 0.5;
    --opacity-strong: 0.9;
    /* Alerts */
    --alert-error: #ef4444;
    --alert-background-error: #fee2e2;
    --alert-warning: #f59e0b;
    --alert-background-warning: #fef3c7;
    --alert-success: #16a34a;
    --alert-background-success: #dcfce7;
    --alert-info: #6366f1;
    --alert-background-info: #e0e7ff;
    --warning-400: #facc15;
    /* Backgrounds */
    --background: #f8fafc;
    --background-active: #e2e8f0;
    --background-hover: #e0e7ff;
    --background-selected: #e2e8f0;
    --background-selected-hover: #c7d2fe;
    --background-brand: #3730a3;
    --background-inverse: #1e293b;
    --background-inverse-hover: #0f172a;
    --background-brand-opacity-weak: #afb1d9;
    --background-brand-opacity-medium: #8b8bc9;
    --background-brand-opacity-strong: #4741aa;
    /* Borders */
    --border-subtle-00: #cbd5e1;
    --border-subtle-01: #cbd5e1;
    --border-subtle-02: #cbd5e1;
    --border-subtle-03: #cbd5e1;
    --border-subtle-selected-01: #94a3b8;
    --border-subtle-selected-02: #94a3b8;
    --border-subtle-selected-03: #94a3b8;
    --border-strong-01: #64748b;
    --border-strong-02: #64748b;
    --border-strong-03: #64748b;
    --border-inverse: #0f172a;
    --border-interactive: #3730a3;
    --border-disabled: #cbd5e1;
    --border-card-01: #e2e8f0;
    --border-card-02: #94a3b8;
    --border-card-03: #e2e8f0;
    --border-error: #ef4444;
    --border-success: #22c55e;
    --border-focus: #818cf8;
    --gray-400: #9ca3af;
    --gray-200: #e5e7eb;
    /* Buttons */
    --button-primary: var(--v-primary-base);
    --button-primary-hover: #4338ca;
    --button-primary-active: #3730a3;
    --button-secondary: #f1f5f9;
    --button-secondary-hover: #c7d2fe;
    --button-secondary-active: #cbd5e1;
    --button-tertiary: var(--v-primary-base);
    --button-tertiary-hover: #4338ca;
    --button-tertiary-active: #3730a3;
    --button-danger-primary: #dc2626;
    --button-danger-secondary: #dc2626;
    --button-danger-hover: #b91c1c;
    --button-danger-active: #991b1b;
    --button-separator: #e2e8f0;
    --button-disabled: #94a3b8;
    --button-on-color: #ffffff;
    /* Data */
    --data-01-primary: #7c3aed;
    --data-01-secondary: #5b21b6;
    --data-01-secondary-light: #baaddf;
    --data-02-primary: #2563eb;
    --data-02-secondary: #1e40af;
    --data-03-primary: #059669;
    --data-03-secondary: #065f46;
    --data-04-primary: #65a30d;
    --data-04-secondary: #3f6212;
    --data-05-primary: #ea580c;
    --data-05-secondary: #c2410c;
    --data-06-primary: #db2777;
    --data-06-secondary: #9d174d;
    --data-07-primary: #c026d3;
    --data-07-secondary: #86198f;
    --data-08-primary: #9333ea;
    --data-08-secondary: #6b21a8;
    --data-09-primary: light-#2563EB;
    --data-09-secondary: light-#1E40AF;
    --data-10-primary: #14b8a6;
    --data-10-secondary: #0f766e;
    --data-visualisation-data-01-primary: #7c3aed7c;
    /* Fields */
    --field-01: #ffffff;
    --field-02: #f1f5f9;
    --field-03: #ffffff;
    --field-hover-01: #f1f5f9;
    --field-hover-02: #e2e8f0;
    --field-hover-03: #f1f5f9;
    /* Gradients */
    --gradient-primary: linear-gradient(249deg, #a8cafc 0%, #aca0f5 97.91%);
    --gradient-blue: linear-gradient(249deg, #3713c6 0%, #320d82 97.91%);
    /* Icons */
    --icon-primary: #1e293b;
    --icon-secondary: #64748b;
    --icon-on-color: #ffffff;
    --icon-inverse: #ffffff;
    --icon-on-color-disabled: #f1f5f9;
    --icon-disabled: #94a3b8;
    --icon-interactive: var(--v-primary-base);
    /* Layers */
    --layer-01: #ffffff;
    --layer-02: #e2e8f0;
    --layer-03: #ffffff;
    --layer-hover-01: #e0e7ff;
    --layer-hover-02: #c7d2fe;
    --layer-hover-03: #e0e7ff;
    --layer-active-01: #f1f5f9;
    --layer-active-02: #e2e8f0;
    --layer-active-03: #cbd5e1;
    --layer-selected-01: #e2e8f0;
    --layer-selected-02: #cbd5e1;
    --layer-selected-03: #e2e8f0;
    --layer-selected-hover-01: #c7d2fe;
    --layer-selected-hover-02: #a5b4fc;
    --layer-selected-hover-03: #c7d2fe;
    --layer-selected-disabled: #64748b;
    /* Links */
    --link-primary: var(--v-primary-base);
    --link-primary-hover: #4338ca;
    --link-secondary: #3730a3;
    --link-inverse: #e0e7ff;
    --link-visited: #312e81;
    /* Tags */
    --tag-background-indigo: #e0e7ff;
    --tag-indigo: #4338ca;
    --tag-hover-indigo: #c7d2fe;
    --tag-background-purple: #ede9fe;
    --tag-color-purple: #7c3aed;
    --tag-hover-purple: #ddd6fe;
    --tag-background-pink: #fce7f3;
    --tag-color-pink: #db2777;
    --tag-hover-pink: #fbcfe8;
    --tag-background-red: #fee2e2;
    --tag-color-red: #dc2626;
    --tag-hover-red: #fecaca;
    --tag-background-orange: #ffedd5;
    --tag-orange: #ea580c;
    --tag-hover-orange: #fed7aa;
    --tag-background-green: #dcfce7;
    --tag-color-green: #16a34a;
    --tag-hover-green: #bbf7d0;
    --tag-background-blue: #dbeafe;
    --tag-blue: #2563eb;
    --tag-hover-blue: #bfdbfe;
    --tag-background-grey: #e2e8f0;
    --tag-grey: #475569;
    --tag-hover-grey: #e2e8f0;
    --tag-background-disabled: #f1f5f9;
    /* Text */
    --text-primary: #0f172a;
    --text-secondary: #475569;
    --text-placeholder: #64748b;
    --text-on-color: #ffffff;
    --text-helper: #334155;
    --text-error: #b91c1c;
    --text-inverse: #ffffff;
    --text-disabled: #94a3b8;
    --text-on-color-disabled: #cbd5e1;
    /* Reports */
    /* Worker Types */
    --wt-thinker: #3b82f6;
    --wt-thinker-accent: #60a5fa;
    --wt-thinker-background: #dbeafe;
    --wt-organiser: #ef4444;
    --wt-organiser-accent: #f87171;
    --wt-organiser-background: #fee2e2;
    --wt-influencer: #8b5cf6;
    --wt-influencer-accent: #9d7fea;
    --wt-influencer-background: #ede9fe;
    --wt-helper: #ec4899;
    --wt-helper-accent: #f472b6;
    --wt-helper-background: #fce7f3;
    --wt-creator: #16a34a;
    --wt-creator-accent: #22c55e;
    --wt-creator-background: #dcfce7;
    --wt-builder: #f97316;
    --wt-builder-accent: #fb923c;
    --wt-builder-background: #ffedd5;
    --wt-base: #312e81;
    /* Entrepreneur */
    --entrepreneur: #ffa500;
    --entrepreneur-accent: #ffb732;
    --entrepreneur-background: #fff6e5;
    /* EQ */
    --managing-emotion: #7c3aed;
    --managing-emotion-background: #d3c0f8;
    --perceiving-emotion: #2563eb;
    --perceiving-emotion-background: #b9cdf7;
    --understanding-emotion: #059669;
    --understanding-emotion-background: #afdcd0;
    /* IQ */
    --maze: #7c3aed;
    --maze-background: #d3c0f8;
    --rock-paper-scissors: #2563eb;
    --rock-paper-scissors-background: #b9cdf7;
    /* Personality Traits */
    --open-mindedness: #7c3aed;
    --open-mindedness-background: #d8c4fa;
    --conscientiousness: #2563eb;
    --conscientiousness-background: #bed1f9;
    --extraversion: #059669;
    --extraversion-background: #b4e0d2;
    --agreeableness: #65a30d;
    --agreeableness-background: #d1e4b7;
    --emotional-stability: #f97316;
    --emotional-stability-background: #fed5ba;
    /* Learning Styles */
    --DC: #7c3aed;
    --DC-background: #d3c1f8;
    --OW: #2563eb;
    --OW-background: #b9cdf7;
    --DT: #059669;
    --DT-background: #b0dcd0;
    --SF: #65a30d;
    --SF-background: #cce0b5;
    /* Value Scales */
    --self_transcendence: #7c3aed;
    --self_transcendence-background: #d3c0f8;
    --self_transcendence-accent: #5b21b6;
    --conservation: #2563eb;
    --conservation-background: #b9cdf7;
    --conservation-accent: #1e40af;
    --self_enhancement: #059669;
    --self_enhancement-background: #a0d0c7;
    --self_enhancement-accent: #065f46;
    --openness_to_change: #65a30d;
    --openness_to_change-background: #cce0b5;
    --openness_to_change-accent: #3f6212;
    /* Prep Strengths */
    --linguistic: #7c3aed;
    --logical-mathematical: #2563eb;
    --spatial: #059669;
    --bodily-kinesthetic: #65a30d;
    --musical: #f97316;
    --interpersonal: #c026d3;
    --intrapersonal: #f97316;
    --naturalistic: #9333ea;
    /* Prep Values */
    --freedom: #7c3aed;
    --universalism: #2563eb;
    --kindness: #059669;
    --conformity: #65a30d;
    --tradition: #f97316;
    --security: #db2777;
    --power: #c026d3;
    --achievement: #9333ea;
    --pleasure: #0ea5e9;
    --experience: #14b8a6;
  }
  [data-theme="dark"] {
    /* Alerts */
    --alert-error: #fca5a5;
    --alert-background-error: #7f1d1d;
    --alert-warning: #fbbf24;
    --alert-background-warning: #92400e;
    --alert-success: #4ade80;
    --alert-background-success: #166534;
    --alert-info: #a5b4fc;
    --alert-background-info: #3730a3;
    /* Backgrounds */
    --background: #0f172a;
    --background-active: #334155;
    --background-hover: #4338ca;
    --background-selected: #334155;
    --background-selected-hover: #4338ca;
    --background-brand: #4338ca;
    --background-inverse: #ffffff;
    --background-inverse-hover: #f8fafc;
    /* Borders */
    --border-subtle-00: #475569;
    --border-subtle-01: #64748b;
    --border-subtle-02: #64748b;
    --border-subtle-03: #475569;
    --border-subtle-selected-01: #94a3b8;
    --border-subtle-selected-02: #94a3b8;
    --border-subtle-selected-03: #64748b;
    --border-strong-01: #94a3b8;
    --border-strong-02: #94a3b8;
    --border-strong-03: #94a3b8;
    --border-inverse: #f8fafc;
    --border-interactive: #6366f1;
    --border-disabled: #1e293b;
    --border-card-01: #334155;
    --border-card-02: #475569;
    --border-card-03: #64748b;
    --border-error: #ef4444;
    --border-success: #22c55e;
    --border-focus: #3730a3;
    /* Buttons */
    --button-primary: #6366f1;
    --button-primary-hover: #818cf8;
    --button-primary-active: var(--v-primary-base);
    --button-secondary: #1e293b;
    --button-secondary-hover: #334155;
    --button-secondary-active: #475569;
    --button-tertiary: #a5b4fc;
    --button-tertiary-hover: #818cf8;
    --button-tertiary-active: var(--v-primary-base);
    --button-danger-primary: #ef4444;
    --button-danger-secondary: #ef4444;
    --button-danger-hover: #f87171;
    --button-danger-active: #dc2626;
    --button-separator: #475569;
    --button-disabled: #64748b;
    --button-on-color: #ffffff;
    /* Data */
    --data-01-primary: #8b5cf6;
    --data-01-secondary: #c4b5fd;
    --data-02-primary: #3b82f6;
    --data-02-secondary: #93c5fd;
    --data-03-primary: #10b981;
    --data-03-secondary: #6ee7b7;
    --data-04-primary: #84cc16;
    --data-04-secondary: #bef264;
    --data-05-primary: #f97316;
    --data-05-secondary: #fdba74;
    --data-06-primary: #ec4899;
    --data-06-secondary: #f9a8d4;
    --data-07-primary: #d946ef;
    --data-07-secondary: #f0abfc;
    --data-08-primary: #a855f7;
    --data-08-secondary: #d8b4fe;
    --data-09-primary: #0ea5e9;
    --data-09-secondary: #7dd3fc;
    --data-10-primary: #14b8a6;
    --data-10-secondary: #5eead4;
    --data-visualisation-data-01-primary: #7c3aed7c;
    /* Fields */
    --field-01: #1e293b;
    --field-02: #475569;
    --field-03: #1e293b;
    --field-hover-01: #475569;
    --field-hover-02: #64748b;
    --field-hover-03: #94a3b8;
    /* Icons */
    --icon-primary: #f1f5f9;
    --icon-secondary: #cbd5e1;
    --icon-on-color: #ffffff;
    --icon-inverse: #0f172a;
    --icon-on-color-disabled: #f1f5f9;
    --icon-disabled: #94a3b8;
    --icon-interactive: #818cf8;
    /* Layers */
    --layer-01: #1e293b;
    --layer-02: #334155;
    --layer-03: #0f172a;
    --layer-hover-01: #4338ca;
    --layer-hover-02: var(--v-primary-base);
    --layer-hover-03: #4338ca;
    --layer-active-01: #334155;
    --layer-active-02: #1e293b;
    --layer-active-03: #334155;
    --layer-selected-01: #334155;
    --layer-selected-02: #1e293b;
    --layer-selected-03: #334155;
    --layer-selected-hover-01: #4338ca;
    --layer-selected-hover-02: var(--v-primary-base);
    --layer-selected-hover-03: #4338ca;
    --layer-selected-disabled: #475569;
    /* Links */
    --link-primary: #a5b4fc;
    --link-primary-hover: #818cf8;
    --link-secondary: var(--v-primary-base);
    --link-inverse: #3730a3;
    --link-visited: #e0e7ff;
    /* Tags */
    --tag-background-indigo: #e0e7ff;
    --tag-indigo: var(--v-primary-base);
    --tag-hover-indigo: #c7d2fe;
    --tag-background-purple: #ede9fe;
    --tag-color-purple: #7c3aed;
    --tag-hover-purple: #ddd6fe;
    --tag-background-pink: #fce7f3;
    --tag-color-pink: #db2777;
    --tag-hover-pink: #fbcfe8;
    --tag-background-red: #fee2e2;
    --tag-color-red: #ef4444;
    --tag-hover-red: #fecaca;
    --tag-background-orange: #ffedd5;
    --tag-orange: #ea580c;
    --tag-hover-orange: #fed7aa;
    --tag-background-green: #dcfce7;
    --tag-color-green: #16a34a;
    --tag-hover-green: #bbf7d0;
    --tag-background-blue: #dbeafe;
    --tag-blue: #2563eb;
    --tag-hover-blue: #bfdbfe;
    --tag-background-grey: #e2e8f0;
    --tag-grey: #64748b;
    --tag-hover-grey: #e2e8f0;
    --tag-background-disabled: #1e293b;
    /* Text */
    --text-primary: #ffffff;
    --text-secondary: #cbd5e1;
    --text-placeholder: #64748b;
    --text-on-color: #ffffff;
    --text-helper: #e2e8f0;
    --text-error: #ef4444;
    --text-inverse: #000000;
    --text-disabled: #94a3b8;
    --text-on-color-disabled: #cbd5e1;
    /* Worker Types */
    --wt-base: #eef2ff;

    /* CTA Backgrounds */
    --cta-background: rgba(49, 46, 129, 0.7);
    --cta-text-color: #ffffff;

    /* Border Radius */
    --border-radius-root: 24px;
    --text-field-border-radius: 12px;
    --chip-label-border-radius: 3px;
    --expansion-panel-border-radius: 0px;

    /* Buttons */
    --btn-border-radius: 12px;
    --btn-letter-spacing: 1px;
    --btn-font-weight: 900;
    --btn-text-transform: uppercase;
    --btn-icon-font-size: 13px;
    --btn-outline-border-width: thin;

    /* Typography */
    --text-line-height: 1.6;
    --text-base-color: black;
  }
}

body,
#app,
.v-card__title,
.v-application,
.v-application .caption,
.v-application .overline,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .body-1,
.v-application .body-2,
.v-application .button,
.v-application .headline,
.v-application .subtitle-1 {
  font-family: "CustomFont", "Roboto", "Avenir", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: var(--text-line-height);
  color: var(--text-base-color);
  word-wrap: break-word;
  word-break: normal !important;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--text-base-color);
  line-height: 1.1;
}

/**
  Main Content Wrapper
 */
.main-content {
  background: #e0e0e0;
}

/** Breadcrumbs **/
.breadcrumbs-bar {
  background: none;
  color: #1a314d;
  text-align: left;
  z-index: 80 !important;
  font-weight: normal;
  padding: 0 0 0 0;

  .separator::before {
    content: "/";
  }

  .first-separator::before {
    content: "•";
  }
}

.breadcrumbs-bar-link {
  text-decoration: none !important;
}

/** Expansion Panels **/
.v-expansion-panel {
  margin-bottom: 20px;
  border: 1px solid #ced3d9;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px;
}

.v-expansion-panel-content__wrap {
  margin: 20px 0;
}

.v-expansion-panel-header--active {
  border-bottom: 1px solid #ced3d9;
}

.v-expansion-panel:first-child,
.v-expansion-panel--active:first-child {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.v-expansion-panel:last-child,
.v-expansion-panel--active:last-child {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.v-expansion-panel:not(:first-child)::after {
  border: none;
}

.v-expansion-panel::before {
  box-shadow: none;
}

/** CTA CARDS **/
.cta-image-card {
  background: linear-gradient(
    to top,
    var(--cta-background),
    var(--cta-background)
  );
}

.cta-image-card.variant-1,
.page-cover.variant-1 {
  background: linear-gradient(
      to top,
      var(--cta-background),
      var(--cta-background)
    ),
    url("assets/img/ctas/variant_1.jpg") center center;
  background-size: cover;
}

.cta-image-card.variant-2,
.page-cover.variant-2 {
  background: linear-gradient(
      to top,
      var(--cta-background),
      var(--cta-background)
    ),
    url("assets/img/ctas/variant_2.jpg") center center;
  background-size: cover;
}

.cta-image-card.variant-3,
.page-cover.variant-3 {
  background: linear-gradient(
      to top,
      var(--cta-background),
      var(--cta-background)
    ),
    url("assets/img/ctas/variant_3.jpg") center center;
  background-size: cover;
}

.cta-card {
  background: var(--v-primary-base);
}

.v-sheet.v-card {
  border-radius: var(--border-radius-root);
}

.logo-circle-image {
  width: 70px; /* or any other size */
  height: 70px;
  border-radius: 50%; /* makes the div circular */
  overflow: hidden; /* ensures the content fits within the circle */
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-circle-image img {
  max-width: 80%;
  max-height: 40px;
  width: auto; /* maintain aspect ratio */
}

/**
  GRADIENTS
 */
.bg-gradient {
  background-image: linear-gradient(
    164deg,
    var(--v-gradient-start-base),
    var(--v-gradient-end-base)
  );
}

.bg-gradient-bar {
  background: linear-gradient(
    89.8deg,
    var(--v-bar-gradient-start-base) 0%,
    var(--v-bar-gradient-end-base) 99.11%
  );
}

/**
  Buttons
 */
.v-btn.outlined-text {
  border: 1px solid #b7b7b7 !important;
}

/**
  Icons
 */
.v-icon.fa {
  font-size: 20px;
  padding: 2px;
}
</style>

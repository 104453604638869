<template>
  <v-menu
    v-if="isStateUserValid"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
  >
    <template #activator="{ on, attrs }">
      <v-btn fab x-small color="app-header-bar-link" dark v-bind="attrs" elevation="0" v-on="on">
        <span v-if="getUserNickname" class="app-header-bar--text">{{ getUserNickname.charAt(0) }}</span>
        <span v-if="!getUserNickname" class="text-uppercase">
          {{ $t("components.common.app_header_user_dropdown_menu.yu") }}
        </span>
      </v-btn>
    </template>

    <v-card class="rounded">
      <v-list>
        <v-list-item>
          <v-btn fab small color="primary" dark class="mr-3">
            <span v-if="getUserNickname" class="app-header-bar-text--text">{{ getUserNickname.charAt(0) }}</span>
            <span v-if="!getUserNickname" class="text-uppercase">
              {{ $t("yu") }}
            </span>
          </v-btn>

          <v-list-item-content>
            <v-list-item-title v-if="getUserFullname">
              {{ getUserFullname }}
            </v-list-item-title>
            <v-list-item-title v-if="!getUserFullname">
              {{ $t("appname_user", { appName: appConfiguration.appName, }) }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="getUserEmail">
              {{ getUserEmail }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list>
        <v-list-item>
          <v-list-item-title>
            <v-btn :to="{ name: 'settings' }" text class="text-capitalize">
              {{ $t("settings") }}
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-btn :to="{ name: 'logout' }" text class="text-capitalize">
              {{ $t("logout") }}
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider v-if="showLanguageDropdown" />
      <v-list v-if="showLanguageDropdown">
        <v-list-item>
          <LanguageSwitcher />
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import translations from "@/components/locales";
import LanguageSwitcher from "@/components/common/LanguageSwitcher.vue";

export default {
  name: "AppHeaderUserDropdownMenu",
  components: { LanguageSwitcher },
  i18n: translations("AppHeaderUserDropdownMenu"),
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapGetters([
      "isStateUserValid",
      "getUserNickname",
      "getUserFullname",
      "getUserEmail",
    ]),
    showLanguageDropdown() {
      return this.appConfiguration.locales.available.length > 1;
    },
  },
};
</script>

<style scoped></style>

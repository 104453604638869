/**
 * Navigation menu items
 *
 * Exports a list of menu items that are used to build the navigation menu
 *
 * Example Structure:
 * {
 *         title: "Menu Item Title", - The title of the menu item
 *         pathName: "routeName", - The name of the route to navigate to
 *         hash: "#defaultSection", - The hash to append to the route
 *         icon: "menu-icon", - The icon to display next to the menu item
 *         feature: Feature.FeatureName - The feature that the menu item is linked to
 *       },
 */
import { AccountType, Feature } from "@YenzaCT/sdk";
import { ReportRouterNames } from "@/library/constants";

const navigationMenuItems = [
  {
    slug: "home",
    pathName: "profileHomePage",
    icon: "fa-chalkboard-user",
    hideForAccounts: [
      AccountType.AssessmentOnly,
      AccountType.AssessmentOnlyBehaviour,
      AccountType.AssessmentOnlyBehaviourPlus,
    ],
    feature: Feature.Profile,
  },
  {
    slug: "reports",
    icon: "fa-book",
    hideForAccounts: [],
    subMenu: [
      {
        slug: "worker_type",
        icon: "fa-compass",
        feature: Feature.WorkerTypeReport,
        pathName: ReportRouterNames[Feature.WorkerTypeReport],
      },
      {
        slug: "entrepreneurial_intent",
        icon: "fa-money-bill-wave",
        feature: Feature.EntrepreneurReport,
        pathName: ReportRouterNames[Feature.EntrepreneurReport],
      },
      {
        slug: "personality_traits",
        icon: "fa-person",
        feature: Feature.PersonalityTraitsReport,
        pathName: ReportRouterNames[Feature.PersonalityTraitsReport],
      },
      {
        slug: "learning_styles",
        icon: "fa-graduation-cap",
        feature: Feature.LearningStylesReport,
        pathName: ReportRouterNames[Feature.LearningStylesReport],
      },
      {
        slug: "emotional_intelligence",
        icon: "fa-masks-theater",
        feature: Feature.EmotionalIntelligenceReport,
        pathName: ReportRouterNames[Feature.EmotionalIntelligenceReport],
      },
      {
        slug: "cognitive_ability",
        icon: "fa-lightbulb",
        feature: Feature.CognitiveAbilityReport,
        pathName: ReportRouterNames[Feature.CognitiveAbilityReport],
      },
    ],
  },
  {
    slug: "navigators",
    icon: "fa-compass",
    hideForAccounts: [
      AccountType.AssessmentOnly,
      AccountType.AssessmentOnlyBehaviour,
      AccountType.AssessmentOnlyBehaviourPlus,
    ],
    subMenu: [
      {
        slug: "careers",
        pathName: "occupationsNavigatorMatches",
        icon: "fa-screwdriver-wrench",
        feature: Feature.CareerExplorer,
      },
      {
        slug: "skills",
        pathName: "skillsNavigator",
        icon: "fa-head-side-virus",
        feature: Feature.SkillsExplorer,
      },
      {
        slug: "subject_choices",
        pathName: "subjectChoiceNavigatorHomePage",
        icon: "fa-graduation-cap",
        feature: Feature.SubjectChoiceExplorer,
      },
      {
        slug: "tertiary_institutions",
        pathName: "tertiaryNavigator",
        feature: Feature.TertiaryInstitutionExplorer,
        icon: "fa-building",
      },
      {
        slug: "funding",
        pathName: "bursaryNavigator",
        icon: "fa-building-columns",
        feature: Feature.BursariesExplorer,
      },
      {
        slug: "resources",
        pathName: "resourcesNavigator",
        icon: "fa-book-open",
        feature: Feature.ResourcesExplorer,
      },
      {
        slug: "funding",
        icon: "fa-piggy-bank",
        feature: Feature.FundingExplorer,
      },
      {
        slug: "jobs",
        icon: "fa-building",
        feature: "jobs_explorer",
      },
      {
        slug: "upliftment_providers",
        pathName: "upliftmentProvidersNavigator",
        icon: "fa-handshake-angle",
        feature: Feature.UpliftmentProviderExplorer,
      },
    ],
  },
  {
    slug: "actions",
    pathName: "actions",
    icon: "fa-chalkboard-user",
    hideForAccounts: [
      AccountType.AssessmentOnly,
      AccountType.AssessmentOnlyBehaviour,
      AccountType.AssessmentOnlyBehaviourPlus,
    ],
    subMenu: [
      {
        slug: "my_cv",
        pathName: "cv",
        icon: "fa-file",
        feature: Feature.Cv,
      }
    ],
  },
];

export { navigationMenuItems };

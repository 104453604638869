<script>
import AppHeaderUserDropdownMenu from "@/components/navigation/AppHeaderUserDropdownMenu.vue";
import AppLogo from "@/components/common/AppLogo.vue";
import TopNavigationBar from "@/components/navigation/TopNavigationBar.vue";
import LanguageSwitcher from "@/components/common/LanguageSwitcher.vue";
import UnauthenticatedAppHeader from "../../../../whitelabel/pluggedin/modules/Public/pages/HomePage/HomePageHeader.vue";
import BreadcrumbBar from "@/components/navigation/BreadcrumbBar.vue";

export default {
  name: "NavBarThemeSection",
  components: {
    BreadcrumbBar,
    UnauthenticatedAppHeader,
    LanguageSwitcher,
    TopNavigationBar,
    AppLogo,
    AppHeaderUserDropdownMenu,
  },
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Navigation</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Un Authenticated</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <UnauthenticatedAppHeader />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Authenticated</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-app-bar flat dense class="app-header-bar" :extended="false">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.smAndDown"
            class="black--text"
          />
          <v-app-bar-nav-icon v-else>
            <AppLogo router-link-name="profileHomePage" :show-icon="true" />
          </v-app-bar-nav-icon>

          <TopNavigationBar v-if="$vuetify.breakpoint.mdAndUp" />

          <v-spacer />

          <AppLogo
            v-if="$vuetify.breakpoint.smAndDown"
            :height="27"
            class="mt-1"
          />

          <v-spacer />
          <LanguageSwitcher v-if="$vuetify.breakpoint.mdAndUp" />
          <v-btn
            icon
            class="hidden-xs-only app-header-bar-text--text"
            @click="triggerHelpDialog"
          >
            <v-icon> fa-circle-question</v-icon>
          </v-btn>
          <AppHeaderUserDropdownMenu />
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h3>Breadcrumbs</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <BreadcrumbBar
          :breadcrumbs="[
            { title: 'Section', routeName: 'themePage' },
            { title: 'Sub Section', routeName: 'themePage' },
            { title: 'Sub Section', routeName: 'themePage' },
            { title: 'Active Section' },
          ]"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped></style>

import environmentConfig from "@/config/environment.config";

export default {
  data() {
    return {
      // For oAuth
      windowObjectReference: null,
      previousUrl: null,
      showAdditionalOptions: false,
    };
  },
  computed: {
    showSnapplifyAuth() {
      return this.appConfiguration.authProviders.snapplify;
    },
    showBasicAuth() {
      return this.appConfiguration.authProviders.basic;
    },
    showFundiAuth() {
      return this.appConfiguration.authProviders.fmOauth;
    },
    showAzureADB2C() {
      return this.appConfiguration.authProviders.azureAdb2c;
    },
    showOIDCAuth() {
      return this.appConfiguration.authProviders.oidc;
    },
    showStandardBankAuth() {
      return this.appConfiguration.authProviders.sbOpenid;
    },
  },
  methods: {
    /**
     * Shows the additional sign in methods when "view more login options" is clicked
     */
    removeWhiteSpace(text) {
      return text.replace(/[\s]/g, "");
    },
    /**
     * Opens a popup for the oAuth provider
     */
    openOAuthRedirect(provider, params = {}) {
      // Track opening of auth provider
      this.$analyticsEvents.openedAuthProvider(provider);

      // Tracking button click
      this.$analyticsEvents.clickedLink("oAuth", provider);

      const urlParams = new URLSearchParams(params).toString();

      // Redirect to auth URL
      window.location.href = `${environmentConfig.serverUrl}/oauth/${provider}/?${urlParams}`;
    },
    /**
     * Opens the PluggedIn Azure AD B2C oAuth and tracks the link click
     *
     * @param route The route to go to - either login or signup
     */
    openAzureADB2CAuthRedirect(route, type = "") {
      const trackingLinkName =
        route === "login"
          ? "are you an existing standard bank customer? | yes button click"
          : "are you an existing standard bank customer? | I just want pluggedin button click";

      // Track opening of auth provider
      this.$analyticsEvents.openedAuthProvider(route);

      // Tracking button click
      this.$analyticsEvents.clickedLink(
        "navigational",
        trackingLinkName,
        "button cta"
      );

      const typeQuery = type ? `/${type}` : "";

      // Redirect to auth URL
      window.location.href = `${environmentConfig.serverUrl}/oauth/azure-ad/${route}${typeQuery}`;
    },
    /**
     * Opens the Standard Bank oAuth and tracks the link click
     *
     * @param route The route to go to - either login or signup
     */
    openStandardBankAuthRedirect(route) {
      const trackingLinkName =
        route === "login"
          ? "are you an existing standard bank customer? | yes button click"
          : "are you an existing standard bank customer? | I just want pluggedin button click";

      // Track opening of auth provider
      this.$analyticsEvents.openedAuthProvider(route);

      // Tracking button click
      this.$analyticsEvents.clickedLink(
        "navigational",
        trackingLinkName,
        "button cta"
      );

      // Redirect to auth URL
      window.location.href = `${environmentConfig.serverUrl}/oauth/stdbank?target=${route}`;
    },
  },
};

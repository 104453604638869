import { render, staticRenderFns } from "./AppHeaderUserDropdownMenu.vue?vue&type=template&id=3737b08d&scoped=true"
import script from "./AppHeaderUserDropdownMenu.vue?vue&type=script&lang=js"
export * from "./AppHeaderUserDropdownMenu.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_css-loader@6.7.3_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.93.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3737b08d",
  null
  
)

export default component.exports
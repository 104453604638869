import store from "@/store";

export default [
  /** ****************************************************************************************************
   * UNAUTHENTICATED ROUTES
   ******************************************************************************************************/
  {
    path: "/purchase/starter",
    beforeEnter: async (to, from, next) => {
      const query = { selectedPlan: "starter" };

      if (from.query.coupon) query.coupon = from.query.coupon;

      return next({
        path: "/signup/create-account",
        query,
      });
    },
  },
  {
    path: "/purchase/commit",
    beforeEnter: async (to, from, next) => {
      const query = { selectedPlan: "power" };

      if (from.query.coupon) query.coupon = from.query.coupon;

      return next({
        path: "/signup/create-account",
        query,
      });
    },
  },
  {
    path: "/signup/redeem",
    name: "startRedeemSignup",
    redirect: {
      path: "/signup/create-account",
    },
  },
  {
    path: "/signup/redeem/existing",
    meta: {
      tracking: {
        sections: ["redeem", "existing"],
      },
    },
    name: "redeemExistingAccount",
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ "@/modules/Onboarding/pages/AuthenticatedRedeemAccessCodePage"
      ),
    beforeEnter: async (to, from, next) => {
      // Check user is authenticated
      // Checks to see if free signup is allowed
      if (!store.getters.getAppConfiguration.onboardingMethods.redeem) {
        return next("/");
      }

      next();
    },
  },
  {
    path: "/signup/create-account",
    meta: {
      isLoggedOut: true,

      tracking: {
        sections: ["signup", "new"],
      },
    },
    name: "freeSignup",
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ "@/modules/Onboarding/pages/SignupPage/SignupPage.vue"
      ),
  },
  {
    path: "/upgrade/redeem",
    meta: {
      tracking: {
        sections: ["redeemUpgrade"],
      },
    },
    name: "redeemUpgrade",
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ "@/modules/Onboarding/pages/OnboardingPage/AccountTypeSelection/AccountTypeSelection.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (!store.getters.getAppConfiguration.onboardingMethods.redeem)
        return next("/");

      if (store.getters.isStateUserValid)
        return next("/upgrade/redeem/existing");

      next();
    },
  },
  /**
   * Onboarding Routes
   */
  {
    path: "/onboarding/welcome",
    name: "onboarding",
    meta: {
      tracking: {
        sections: ["onboarding"],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "authentication" */ "@/modules/Onboarding/pages/OnboardingPage/OnboardingPage.vue"
      ),
    beforeEnter: async (to, from, next) => {
      // If they have not completed initial onboarding
      if (await store.getters.appStage !== "onboarding") {
        return next({ name: "profileHomePage" });
      }
      next();
    },
  },
];

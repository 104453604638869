<template>
  <v-sheet max-width="400px" width="100%" class="ma-auto" elevation="0">
    <v-row no-gutters>
      <v-col v-if="$route.query.loginError || error" cols="12" class="px-6">
        <v-alert
          v-if="$route.query.loginError === 'socialSignOnConflict'"
          max-width="800px"
          class="ma-auto"
        >
          <b>
            {{
              $t("you_are_already_member_appname", {
                appName: appConfiguration.appName,
              })
            }}
          </b>
          <br>
          {{ $t("sign_into_existing_account_with_email_password") }}
        </v-alert>

        <v-alert
          v-if="error"
          max-width="500px"
          type="error"
          class="ma-auto mb-6"
        >
          {{ $t("incorrect_details") }}
        </v-alert>

        <v-alert
          v-if="$route.query.loginError === 'error'"
          max-width="800px"
          type="error"
          class="ma-auto mb-6"
        >
          {{ $t("error") }}
        </v-alert>

        <v-alert
          v-if="$route.query.loginError === 'socialSignNotFound'"
          max-width="800px"
          class="ma-auto mb-6"
        >
          {{ $t("account_not_found") }}
        </v-alert>
      </v-col>
      <v-col cols="12" class="px-4">
        <v-form
          v-if="showBasicAuth"
          ref="loginForm"
          v-model="isFormValid"
          class="login-form"
        >
          <v-radio-group v-model="method" row>
            <v-radio value="email" :label="$t('email')" />
            <v-radio value="phone" :label="$t('phone')" />
          </v-radio-group>

          <InternationalPhoneNumberInput
            v-if="method === 'phone'"
            :dense="$vuetify.breakpoint.xsOnly"
            @input="onInput"
          />

          <v-text-field
            v-if="method === 'email'"
            ref="usernameInput"
            v-model="email"
            class="login-form-username-field my-2"
            data-cy="emailPhoneText"
            :label="$t('email')"
            :placeholder="$t('enter_email')"
            required
            name="username"
            persistent-placeholder
            :rules="emailRules"
            outlined
            :dense="$vuetify.breakpoint.xsOnly"
            validate-on-blur
            @keydown.space="(event) => event.preventDefault()"
            @input="email = removeWhiteSpace(email)"
          />
          <v-text-field
            ref="passwordInput"
            v-model="password"
            class="login-form-password-field my-2"
            data-cy="passwordText"
            :label="$t('password')"
            :placeholder="$t('password')"
            name="password"
            :dense="$vuetify.breakpoint.xsOnly"
            required
            persistent-placeholder
            :rules="passwordRules"
            outlined
            :type="showPassword ? 'text' : 'password'"
            @keyup.enter="isFormValid ? submitForm() : null"
          >
            <template #append>
              <v-btn
                :to="{ name: 'forgotPassword' }"
                text
                class="mt-n2 body-2 text--lighten-4"
                color="primary"
              >
                <span v-if="$vuetify.breakpoint.xs">
                  {{ $t("forgot") }}
                </span>
                <span v-else>
                  {{ $t("forgot_password") }}
                </span>
              </v-btn>
            </template>
          </v-text-field>
          <v-btn
            ref="submitButton"
            class="login-form-submit-button primary rounded"
            :loading="processingLogin"
            block
            large
            elevation="0"
            data-cy="loginButton"
            :disabled="!isValid || processingLogin"
            @click.native="submitForm"
          >
            {{ $t("log_in") }}
          </v-btn>
        </v-form>

        <div
          v-if="showBasicAuth && showAdditionalOptions"
          class="text-center pt-3 grey--text hidden-xs-only"
        >
          or
        </div>
        <v-btn
          v-if="showSnapplifyAuth"
          block
          large
          elevation="0"
          class="ma-auto mt-3 mb-3 rounded"
          @click="openOAuthRedirect('snapplify')"
        >
          <img
            height="25px"
            class="mr-1"
            src="../../../../public/img/icons/icon-snapplify.png"
          >
          {{ $t("log_in_snapplify") }}
        </v-btn>

        <v-btn
          v-if="showFundiAuth"
          block
          large
          elevation="0"
          class="ma-auto mt-3 mb-3 rounded"
          @click="openOAuthRedirect('fundi')"
        >
          <AppLogo :show-icon="true" />
          <v-img height="20px" class="mr-1">
            {{ $t("log_in_fundi") }}
          </v-img>
        </v-btn>

        <v-btn
          v-if="showOIDCAuth"
          block
          large
          elevation="0"
          class="ma-auto mt-6 mb-3 rounded white--text"
          color="primary"
          @click="openOAuthRedirect('oidc')"
        >
          <AppLogo :show-icon="true" />
          <v-img height="25px" class="mr-1 white--text">
            {{ $t("login") }}
          </v-img>
        </v-btn>

        <!--  Standard Bank Log In Button-->
        <v-btn
          v-if="showStandardBankAuth"
          block
          dark
          large
          elevation="0"
          color="white black--text"
          class="ma-auto mt-3 mb-3 rounded"
          @click="openOAuthRedirect('stdbank')"
        >
          {{ $t("log_in_standard_bank") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import authMixin from "@/library/mixins/auth.mixin";
import AppLogo from "@/components/common/AppLogo.vue";
import router from "@/router/router";
import translations from "@/modules/Authentication/locales";
import api from "@/library/api.lib";
import analyticsEvents from "@/library/analyticsEvents.lib";
import InternationalPhoneNumberInput from "@/components/common/InternationalPhoneNumberInput.vue";

export default {
  name: "LoginMethodsCard",
  i18n: translations("LoginMethodsCard"),
  components: { InternationalPhoneNumberInput, AppLogo },
  mixins: [authMixin],
  props: {
    showInModal: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      phone: {
        number: "",
        valid: false,
        country: undefined,
      },
      email: "",
      method: "email",
      showAdditionalOptions: false,
      isFormValid: false,
      error: false,
      errorMessage: this.$t("login_details_incorrect"),
      processingLogin: false,
      showPassword: false,
      password: "",
      passwordRules: [(v) => !!v || this.$t("password_required")],
      emailRules: [
        (v) => !!v || this.$t("email_required"),
        (v) =>
          /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(v) ||
          this.$t("email_invalid"),
      ],
    };
  },
  computed: {
    isValid() {
      if (this.method === "phone") return this.isFormValid && this.phone.valid;
      return this.isFormValid;
    },
  },
  /**
   * Prepares the login form to show the available authentication methods.
   * If basic auth is not available, the form is hidden and the oAuth providers are shown instead
   */
  mounted() {
    if (!this.showBasicAuth) {
      this.showAdditionalOptions = true;
    }
  },
  methods: {
    removeWhiteSpace(text) {
      return text.replace(/[\s]/g, "").toLowerCase();
    },
    onInput(data) {
      this.phone.number = data.number;
      this.phone.valid = data.valid;
    },
    async submitForm() {
      // Destructure the username and password from this
      const { method, email, phone, password } = this;
      let username, response;

      if (method === "phone") username = phone.number;
      else username = email;

      // Flag to indicate if login is in progress
      // Reset any previous errors
      this.error = false;

      // Check if username and password are present
      try {
        this.processingLogin = true;

        response = await api(false).post("auth/login", { username, password });
        await this.handleSuccessfulLogin(response.data);
      } catch (e) {
        this.handleLoginError(this);
        return e.response;
      } finally {
        this.processingLogin = false;
      }
    },
    async handleSuccessfulLogin(user) {
      // Set the users authenticated state
      await this.$store.dispatch("setupUser", {
        user,
      });
      // TRACK: Set the users identity for analytics
      analyticsEvents.authenticatedUser();

      // Emit a successful login event to the parent component holding the login form
      if (this.nextAction === "emitSuccess") {
        this.$emit("success");
        return;
      }

      if (router.currentRoute.query.redirect) {
        return router.push(router.currentRoute.query.redirect);
      }

      return router.push({ name: "profileHomePage" });
    },
    handleLoginError() {
      this.error = true;
      this.processingLogin = false;
    },
  },
};
</script>

<style scoped></style>

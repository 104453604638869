import ReportsPage from "@/modules/Reports/pages/ReportsPage.vue";
import store from "@/store";
import { Feature } from "@YenzaCT/sdk";
import { ReportRouterNames } from "@/library/constants";

/**
 * Checks if the user has completed the Big5, and if they have, redirect to the
 * report
 * @param to
 * @param from
 * @param next
 */

/**
 * Checks if the user has permission to access this assessment report,
 * if not redirects them to the dashboard
 * @param to
 * @param from
 * @param next
 * @param reportKey
 * @returns {*}
 */
const checkIfReportAccessAllowed = async (to, from, next, feature) => {
  // If the user is not allowed access to this assessment, go to report page
  if (!store.getters.canAccessFeature(feature)) {
    return next({ name: ReportRouterNames[Feature.WorkerTypeReport] });
  }

  return next();
};

export default [
  // TODO: REMOVE THIS ONCE ASSESSMENTS ARE REFACTORED
  {
    path: "/report-redirect/:assessment",
    name: "assessmentReportPage",
    redirect: (to) => {
      switch (to.params.assessment) {
      case "personality-guide":
        return { name: ReportRouterNames[Feature.PersonalityTraitsReport] };
      case "worker-type":
        return { name: ReportRouterNames[Feature.WorkerTypeReport] };
      case "emotional-intelligence":
        return {
          name: ReportRouterNames[Feature.EmotionalIntelligenceReport],
        };
      case "cognitive-ability":
        return { name: ReportRouterNames[Feature.CognitiveAbilityReport] };
      case "learning-styles":
        return { name: ReportRouterNames[Feature.LearningStylesReport] };
      default:
        return { name: ReportRouterNames[Feature.WorkerTypeReport] };
      }
    },
  },

  {
    path: "/reports",
    component: ReportsPage,
    meta: {
      title: "Report",
    },
    children: [
      {
        name: ReportRouterNames[Feature.LearningStylesReport],
        path: "learning-styles",
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/LearningStyles/ReportPage/LearningStylesReportPage.vue"
          ),
        meta: {
          feature: Feature.LearningStylesReport,
          breadcrumbs: [{ title: "Reports" }, { title: "Learning Styles" }],
          tracking: {
            sections: ["my self", "reports", "learning styles"],
          },
        },
        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.LearningStylesReport
          );
        },
      },
      {
        name: "learningStylesQuizPracticePage",
        path: "learning-styles/quiz-practice",
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/LearningStyles/QuizPracticePage/LearningStylesPracticePage.vue"
          ),
        meta: {
          feature: Feature.LearningStylesReport,
          breadcrumbs: [{ title: "Reports" }, { title: "Learning Styles" }],
          tracking: {
            sections: ["my self", "reports", "learning styles"],
          },
        },
        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.LearningStylesReport
          );
        },
      },
      {
        name: "learningStylesQuizPage",
        path: "learning-styles/quiz",
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/LearningStyles/QuizPage/LearningStylesQuizPage.vue"
          ),
        meta: {
          feature: Feature.LearningStylesReport,
          breadcrumbs: [{ title: "Reports" }, { title: "Learning Styles" }],
          tracking: {
            sections: ["my self", "reports", "learning styles Quiz"],
          },
        },
        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.LearningStylesReport
          );
        },
      },
      {
        name: ReportRouterNames[Feature.EntrepreneurReport],
        path: "entrepreneurship",
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/EntrepreneurialIntent/EntrepreneurReportPage/EntrepreneurReportPage.vue"
          ),
        meta: {
          feature: Feature.EntrepreneurReport,
          tracking: {
            sections: ["my self", "reports", "entrepreneurship"],
          },
          breadcrumbs: [
            { title: "Reports" },
            { title: "Entrepreneurship Interest" },
          ],
        },
        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.EntrepreneurReport
          );
        },
      },
      {
        path: "worker-type",
        meta: {
          feature: Feature.WorkerTypeReport,
          appStage: "workerType",
          tracking: {
            sections: ["my self", "reports", "worker type"],
          },
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/HollandCode/ReportPage/index.vue"
          ),
        name: ReportRouterNames[Feature.WorkerTypeReport],
        props: true,
      },
      {
        path: "worker-type/quiz-practice",
        meta: {
          feature: Feature.WorkerTypeReport,
          appStage: "workerType",
          tracking: {
            sections: ["my self", "reports", "worker type", "quiz practice"],
          },
          breadcrumbs: [
            { title: "Self", routeName: "selfDashboard" },
            { title: "Worker Type" },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/HollandCode/QuizPracticePage/QuizPracticePage.vue"
          ),
        name: "hollandCodeQuizPracticePage",
        props: true,
      },
      {
        path: "worker-type/quiz",
        meta: {
          feature: Feature.WorkerTypeReport,
          appStage: "workerType",
          tracking: {
            sections: ["my self", "reports", "worker type", "quiz"],
          },
          breadcrumbs: [
            { title: "Self", routeName: "selfDashboard" },
            { title: "Worker Type" },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/HollandCode/QuizPage/HollandCodeQuizPage.vue"
          ),
        name: "hollandCodeQuizPage",
        props: true,
      },
      {
        path: "worker-type/lesson",
        meta: {
          feature: Feature.WorkerTypeReport,
          tracking: {
            sections: ["my self", "reports", "worker type"],
          },
          breadcrumbs: [
            { title: "Self", routeName: "selfDashboard" },
            { title: "Worker Type" },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/HollandCode/LessonPage/index.vue"
          ),
        name: "hollandCodeLessonPage",
        props: true,
      },
      {
        path: "personality-guide",
        meta: {
          feature: Feature.PersonalityTraitsReport,
          tracking: {
            sections: ["my self", "reports", "personality guide"],
          },
          breadcrumbs: [{ title: "Reports" }, { title: "Personality Guide" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/Big5/Big5ReportPage/index.vue"
          ),

        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.PersonalityTraitsReport
          );
        },

        name: ReportRouterNames[Feature.PersonalityTraitsReport],
        props: true,
      },
      {
        path: "personality-guide/quiz-practice",
        meta: {
          feature: Feature.PersonalityTraitsReport,
          tracking: {
            sections: ["my self", "reports", "personality guide"],
          },
          breadcrumbs: [{ title: "Reports" }, { title: "Personality Guide" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/Big5/QuizPracticePage/Big5PracticePage.vue"
          ),

        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.PersonalityTraitsReport
          );
        },

        name: "big5QuizPracticePage",
        props: true,
      },
      {
        path: "personality-guide/quiz",
        meta: {
          feature: Feature.PersonalityTraitsReport,
          tracking: {
            sections: ["my self", "reports", "personality guide quiz"],
          },
          breadcrumbs: [{ title: "Reports" }, { title: "Personality Guide" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/Big5/QuizPage/Big5QuizPage.vue"
          ),

        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.PersonalityTraitsReport
          );
        },

        name: "big5QuizPage",
        props: true,
      },
      {
        path: "emotional-intelligence",
        meta: {
          feature: Feature.EmotionalIntelligenceReport,
          tracking: {
            sections: ["my self", "reports", "emotional intelligence"],
          },
          breadcrumbs: [
            { title: "Reports" },
            {
              title: "Emotional Intelligence",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/EmotionalIntelligence/EmotionalIntelligenceReportPage/index.vue"
          ),

        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.EmotionalIntelligenceReport
          );
        },
        name: ReportRouterNames[Feature.EmotionalIntelligenceReport],
        props: true,
      },
      {
        path: "emotional-intelligence/perceiving-emotions",
        meta: {
          feature: Feature.EmotionalIntelligenceReport,
          tracking: {
            sections: ["my self", "reports", "emotional intelligence"],
          },
          breadcrumbs: [
            { title: "Reports" },
            {
              title: "Emotional Intelligence",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/EmotionalIntelligence/QuizPracticePages/PerceivingEmotionsPracticePage.vue"
          ),

        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.EmotionalIntelligenceReport
          );
        },
        name: "perceivingEmotionsPracticePage",
        props: true,
      },
      {
        path: "emotional-intelligence/perceiving-emotions/quiz",
        meta: {
          feature: Feature.EmotionalIntelligenceReport,
          tracking: {
            sections: [
              "my self",
              "reports",
              "emotional intelligence",
              "perceiving emotions",
              "quiz",
            ],
          },
          breadcrumbs: [
            { title: "Reports" },
            {
              title: "Emotional Intelligence",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/EmotionalIntelligence/PerceivingEmotionsQuiz/PerceivingEmotionsQuiz.vue"
          ),

        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.EmotionalIntelligenceReport
          );
        },
        name: "perceivingEmotionsQuiz",
        props: true,
      },
      {
        path: "emotional-intelligence/understanding-emotions/quiz",
        meta: {
          feature: Feature.EmotionalIntelligenceReport,
          tracking: {
            sections: [
              "my self",
              "reports",
              "emotional intelligence",
              "perceiving emotions",
              "quiz",
            ],
          },
          breadcrumbs: [
            { title: "Reports" },
            {
              title: "Emotional Intelligence",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/EmotionalIntelligence/UnderstandingEmotionsQuiz/UnderstandingEmotionsQuiz.vue"
          ),

        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.EmotionalIntelligenceReport
          );
        },
        name: "understandingEmotionsQuiz",
        props: true,
      },
      {
        path: "emotional-intelligence/managing-emotions/quiz",
        meta: {
          feature: Feature.EmotionalIntelligenceReport,
          tracking: {
            sections: [
              "my self",
              "reports",
              "emotional intelligence",
              "managing emotions",
              "quiz",
            ],
          },
          breadcrumbs: [
            { title: "Reports" },
            {
              title: "Emotional Intelligence",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/EmotionalIntelligence/ManagingEmotionsQuiz/ManagingEmotionsQuiz.vue"
          ),

        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.EmotionalIntelligenceReport
          );
        },
        name: "managingEmotionsQuiz",
        props: true,
      },
      {
        path: "emotional-intelligence/understanding-emotions",
        meta: {
          feature: Feature.EmotionalIntelligenceReport,
          tracking: {
            sections: ["my self", "reports", "emotional intelligence"],
          },
          breadcrumbs: [
            { title: "Reports" },
            {
              title: "Emotional Intelligence",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/EmotionalIntelligence/QuizPracticePages/UnderstandingEmotionsPracticePage.vue"
          ),

        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.EmotionalIntelligenceReport
          );
        },
        name: "understandingEmotionsPracticePage",
        props: true,
      },
      {
        path: "emotional-intelligence/managing-emotions",
        meta: {
          feature: Feature.EmotionalIntelligenceReport,
          tracking: {
            sections: ["my self", "reports", "emotional intelligence"],
          },
          breadcrumbs: [
            { title: "Reports" },
            {
              title: "Emotional Intelligence",
            },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/EmotionalIntelligence/QuizPracticePages/ManagingEmotionsPracticePage.vue"
          ),

        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.EmotionalIntelligenceReport
          );
        },
        name: "managingEmotionsPracticePage",
        props: true,
      },
      {
        path: "cognitive-ability",
        meta: {
          feature: Feature.CognitiveAbilityReport,
          tracking: {
            sections: ["my self", "reports", "cognitive ability"],
          },
          breadcrumbs: [{ title: "Reports" }, { title: "Cognitive Ability" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/CoginitiveIntelligence/CoginitiveIntelligenceReportPage/CognitiveIntelligenceReportPage.vue"
          ),
        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.CognitiveAbilityReport
          );
        },
        name: ReportRouterNames[Feature.CognitiveAbilityReport],
        props: true,
      },
      {
        path: "cognitive-ability/maze",
        meta: {
          feature: Feature.CognitiveAbilityReport,
          tracking: {
            sections: ["my self", "reports", "cognitive ability"],
          },
          breadcrumbs: [{ title: "Reports" }, { title: "Cognitive Ability" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/CoginitiveIntelligence/QuizPracticePages/MazePracticePage.vue"
          ),
        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.CognitiveAbilityReport
          );
        },
        name: "mazeEmotionsPracticePage",
        props: true,
      },
      {
        path: "cognitive-ability/maze/quiz",
        meta: {
          feature: Feature.CognitiveAbilityReport,
          tracking: {
            sections: ["my self", "reports", "cognitive ability", "quiz"],
          },
          breadcrumbs: [{ title: "Reports" }, { title: "Cognitive Ability" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/CoginitiveIntelligence/MazeQuiz/MazeQuizPage.vue"
          ),
        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.CognitiveAbilityReport
          );
        },
        name: "mazeQuizPage",
        props: true,
      },
      {
        path: "cognitive-ability/rock-paper-scissors/quiz",
        meta: {
          feature: Feature.CognitiveAbilityReport,
          tracking: {
            sections: ["my self", "reports", "cognitive ability", "quiz"],
          },
          breadcrumbs: [{ title: "Reports" }, { title: "Cognitive Ability" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/CoginitiveIntelligence/RockPaperScissorsQuiz/RockPaperScissorsQuiz.vue"
          ),
        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.CognitiveAbilityReport
          );
        },
        name: "rockPaperScissorsQuizPage",
        props: true,
      },
      {
        path: "cognitive-ability/rock-paper-scissors",
        meta: {
          feature: Feature.CognitiveAbilityReport,
          tracking: {
            sections: ["my self", "reports", "cognitive ability"],
          },
          breadcrumbs: [{ title: "Reports" }, { title: "Cognitive Ability" }],
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "@/modules/Reports/pages/CoginitiveIntelligence/QuizPracticePages/RockPaperSciPracticePage.vue"
          ),
        beforeEnter: async (to, from, next) => {
          checkIfReportAccessAllowed(
            to,
            from,
            next,
            Feature.CognitiveAbilityReport
          );
        },
        name: "rockPaperSciPracticePracticePage",
        props: true,
      },
    ],
  },
];

<template>
  <div>
    <v-app-bar
      color="app-header-bar"
      :height="$vuetify.breakpoint.xs ? 80 : 107"
      flat
      :elevation="$vuetify.breakpoint.xs ? 4 : 0"
      dark
      class="header"
    >
      <v-container>
        <v-row>
          <v-col cols="4" md="6">
            <v-app-bar-nav-icon
              class="hidden-sm-and-up"
              @click.stop="menuDrawer = !menuDrawer"
            />
            <AppLogo
              router-link-name="home"
              type="white"
              class="hidden-xs-only"
            />
          </v-col>
          <v-spacer />
          <v-col class="hidden-sm-and-up text-right" align-self="center">
            <img
              src="/img/graphics/home-page/sb-badge.png"
              class="hidden-sm-and-up pt-2"
            />
          </v-col>
          <v-col cols="8" md="6" class="text-right hidden-xs-only">
            <v-btn
              v-if="!isStateUserValid"
              outlined
              color="white"
              class="mr-4"
              @click="openLoginDialog('home | login button click')"
            >
              Sign In
            </v-btn>

            <v-btn
              v-if="!isStateUserValid"
              color="secondary"
              @click="signupModal = true"
            >
              Register
            </v-btn>
            <v-btn
              v-if="isStateUserValid"
              color="secondary"
              class="mr-4"
              :to="{ name: 'profileHomePage' }"
            >
              My Dashboard
            </v-btn>
            <v-btn v-if="isStateUserValid" :to="{ name: 'logout' }">
              Logout
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="menuDrawer" absolute temporary>
      <v-list-item color="white">
        <v-list-item-content>
          <AppLogo router-link-name="home" />
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-content>
          <v-btn
            outlined
            color="secondary"
            class="mr-4"
            @click="openLoginDialog('home | login button click')"
          >
            Sign In
          </v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-btn
            color="secondary"
            @click="openSignupDialog('home | register button click')"
          >
            Register
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-navigation-drawer>

    <!---*********************************
    ***** Authentication Modal
    ************************************-->
    <v-dialog
      v-model="loginModal"
      max-width="600px"
      class="authentication-popup"
    >
      <v-card class="pa-3 pa-sm-6">
        <v-row class="text-right">
          <v-icon class="ml-auto pa-2" @click="loginModal = false">
            fa-xmark
          </v-icon>
        </v-row>

        <v-card-title
          class="text-center font-weight-bold authentication-popup-title"
        >
          <div class="ma-auto">Sign-in to PLUGGEDIN</div>
        </v-card-title>
        <v-card-text class="text-center">
          <img
            :height="$vuetify.breakpoint.xs ? '100px' : '170px'"
            alt="Star with Standard Bank Logo."
            src="/img/graphics/yenza-star.svg"
            class="ma-auto mb-6"
          />
          <v-btn
            v-if="!isStateUserValid"
            block
            color="primary"
            :small="$vuetify.breakpoint.xs"
            class="authentication-popup-signup-button"
            @click="openAzureADB2CAuthRedirect('login')"
          >
            Sign-in with Email or Phone
          </v-btn>

          <v-btn
            block
            color="primary"
            :small="$vuetify.breakpoint.xs"
            class="mt-6 authentication-popup-login-button"
            @click="openStandardBankAuthRedirect()"
          >
            <span class="hidden-xs-only"
              >Sign-in using your Standard Bank credentials</span
            >
            <span class="hidden-sm-and-up">Sign-in using Standard Bank</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!---*********************************
  ***** Authentication Modal
  ************************************-->
    <v-dialog
      v-model="signupModal"
      max-width="600px"
      class="authentication-popup"
    >
      <v-card class="pa-3 pa-sm-6">
        <v-row class="text-right">
          <v-icon class="ml-auto pa-2" @click="signupModal = false">
            fa-xmark
          </v-icon>
        </v-row>

        <v-card-title
          class="text-center font-weight-bold authentication-popup-title"
        >
          <div class="ma-auto">Sign up to PluggedIn</div>
        </v-card-title>
        <v-card-text class="text-center">
          <img
            :height="$vuetify.breakpoint.xs ? '100px' : '170px'"
            alt="Star with Standard Bank Logo."
            src="/img/graphics/yenza-star.svg"
            class="ma-auto mb-6"
          />
          <v-btn
            v-if="!isStateUserValid"
            color="primary"
            class="mr-4 mb-6"
            block
            @click="openAzureADB2CAuthRedirect('signup', 'email')"
          >
            Sign up with email
          </v-btn>

          <v-btn
            v-if="!isStateUserValid"
            color="primary"
            class="mr-4"
            block
            @click="openAzureADB2CAuthRedirect('signup', 'phone')"
          >
            Sign up with phone
          </v-btn>

          <v-btn
            block
            color="primary"
            class="mt-6 authentication-popup-login-button"
            @click="openStandardBankAuthRedirect()"
          >
            <span class="hidden-xs-only">
              Sign up with Standard Bank credentials
            </span>
            <span class="hidden-sm-and-up">Sign up with Standard Bank</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppLogo from "@/components/common/AppLogo.vue";
import { mapGetters } from "vuex";
import authMixin from "@/library/mixins/auth.mixin";

export default {
  name: "UnauthenticatedAppHeader",
  components: { AppLogo },
  mixins: [authMixin],
  data: () => ({
    menuDrawer: false,
    signupModal: false,
    loginModal: false,
  }),
  computed: {
    ...mapGetters(["isStateUserValid"]),
  },
  methods: {
    /**
     * Opens the signup dialog and tracks the link click
     *
     * @param trackingLinkName The clicked button name
     */
    openSignupDialog(trackingLinkName) {
      this.$analyticsEvents.clickedLink(
        "navigational",
        trackingLinkName,
        "button cta"
      );

      this.signupModal = true;
    },
    openLoginDialog(trackingLinkName) {
      this.$analyticsEvents.clickedLink(
        "navigational",
        trackingLinkName,
        "button cta"
      );

      this.loginModal = true;
    },
  },
};
</script>

export default [
  {
    path: "/home",
    name: "home",
    meta: {
      isLoggedOut: true,
      tracking: {
        sections: ["home"]
      }
    },
    component: () =>
      import(/* webpackChunkName: "authentication" */ "@/modules/Public/pages/HomePage")
  },
  {
    name: "privacyPage",
    meta: {
      ignoreAuth: true,
      tracking: {
        sections: ["privacy"]
      }
    },
    path: "/privacy",
    component: () =>
      import(/* webpackChunkName: "authentication" */ "@/modules/Public/pages/PrivacyPage"),
  },
  {
    name: "termsPage",
    meta: {
      ignoreAuth: true,
      tracking: {
        sections: ["terms"]
      }
    },
    path: "/terms",
    component: () =>
      import(/* webpackChunkName: "authentication" */ "@/modules/Public/pages/TermsAndConditionsPage"),
  }
];

<script>
import translations from "@/components/locales";

export default {
  name: "InternationalPhoneNumberInput",
  i18n: translations("InternationalPhoneNumberInput"),
  props: {
    initialPhone: {
      type: String,
      default: null,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      phone: {
        number: "",
        valid: false,
        country: undefined,
      },
    };
  },
  mounted() {
    this.phone.number = this.initialPhone;
  },
  methods: {
    onInput(formattedNumber, { number, valid }) {
      this.phone.number = number.e164;
      this.phone.valid = valid;
      this.$emit("input", this.phone);
    },
  },
};
</script>

<template>
  <vue-tel-input-vuetify
    :preferred-countries="['za', 'et', 'ae', 'ke']"
    mode="international"
    validate-on-blur
    :valid-characters-only="true"
    :value="initialPhone"
    :label="$t('enter_your_phone_number')"
    outlined
    :disabled="disabled"
    :dense="dense"
    class="phone-input mt-2"
    @input="onInput"
  />
</template>

<style scoped>
>>> fieldset {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
>>> .country-code .v-text-field--outlined fieldset {
  border-right: none;
  border-left: 1px solid currentColor !important;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
